<template>
  <div>
    <md-card v-if="showForm">
      <md-card-header>
        <h4
          class="title"
          style="font-weight:bold"
        >
          Upload Form
        </h4>

        <ul class="subtitle">
          <li>
            Files size cannot be greater than 10MB. You can reduce the file size <a
              href="https://www.adobe.com/ie/acrobat/online/compress-pdf.html"
              target="_blank"
            >HERE</a>
          </li>
          <li>
            Files extension must be <b>.png/.jpg./jpeg./pdf./docx./xlsx</b>
          </li>
        </ul>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item">
            <form-text
              v-model="form.title"
              name="title"
              icon="description"
              label="Title"
            />
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <FileTypesSelect
              v-model="form.file_type_id"
              :only-admin-types="true"
            />
          </div>
          <div class="md-layout-item pt-3">
            <form-uploader
              v-model="form.file"
              :max-size="10*1024"
              accept=".png, .jpg, .jpeg, .pdf, .docx, .xlsx"
              @error="onFileError"
            >
              <md-button
                class="md-primary md-sm w-100"
              >
                Choose File
              </md-button>
              <b
                v-if="form.file"
                class="text-success"
              >{{ form.file.name }}</b>
            </form-uploader>
          </div>
        </div>
        <div class="md-layout">
          <div class="md-layout-item">
            <md-button
              class="md-primary md-sm w-100"
              @click="uploadForm"
            >
              <md-icon class="material-icons">
                save
              </md-icon>
              Upload
            </md-button>
          </div>
        </div>
      </md-card-content>
    </md-card>

    <md-card>
      <md-card-header>
        <md-button
          v-if="!showForm"
          class="md-primary md-sm"
          @click="showForm = true"
        >
          <md-icon class="material-icons">
            save
          </md-icon>
          Upload New File
        </md-button>
      </md-card-header>

      <md-card-content>
        <vue-table
          v-if="vTable.headers"
          ref="vtable"
          :values="vTable.values"
          :headers="vTable.headers"
          :actions="vTable.actions"
          :options="{
            tableClass:'table'
          }"
          @changed="getFiles"
          @onDelete="onDelete"
          @onPreview="onPreview"
        />
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import { FormText, FormUploader } from '@/components/Inputs';
import { FileTypesSelect } from '@/components/Inputs/selects';

export default {
  components: {
    VueTable,
    FormUploader,
    FormText,
    FileTypesSelect,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'created_at',
            mask: 'uploaded',
            dateFormat: true,
            sortable: true,
          },
          {
            title: 'title',
            mask: 'file name',
            sortable: true,
          },
          {
            title: 'file_type.name',
            mask: 'file type',
            sortable: true,
          },
          {
            title: 'uploader.first_name',
            mask: 'created by',
            sortable: true,
          },
        ],
        actions: [
          {
            buttonClass: 'md-info',
            tooltip: 'Preview File',
            callback: 'onPreview',
            icon: 'visibility',
          },
          {
            buttonClass: 'md-danger',
            tooltip: 'Delete File',
            callback: 'onDelete',
            icon: 'close',
          },
        ],
        values: {},
      },
      showForm: false,
      form: {
        file_type_id: null,
        title: null,
        file: null,
      },
    };
  },
  mounted() {
    this.$refs.vtable.init();
  },
  methods: {
    getFiles(params) {
      this.request(this.$API.FILE_TYPES_ADMIN.paginableUrl(params), 'get', null, ({ data }) => {
        this.vTable.values = data;
      });
    },
    onDelete(item) {
      this.fireConfirm(
        'Deleting File',
        'Are you sure you want to delete this File?',
      ).then(() => {
        this.request(`${this.$API.FILE_TYPES_ADMIN}${item.file_id}`, 'delete', null, () => {
          this.fireSuccess('File deleted successfully!');
          this.$refs.vtable.init();
        });
      });
    },
    onPreview(item) {
      this.openFileUrl(item.file_id);
    },
    onFileError(error) {
      this.fireError(error);
    },
    async uploadForm() {
      if (!this.form.file_type_id || !this.form.title || !this.form.file) {
        this.fireError(
          (!this.form.file_type_id && 'Select a file type to continue')
          || (!this.form.title && 'Insert a file title to continue')
          || (!this.form.file && 'Upload a file to continue'),
        );

        return;
      }

      const formData = new FormData();
      formData.append('file', this.form.file);
      formData.append('title', this.form.title);
      formData.append('file_type_id', this.form.file_type_id);

      this.request(this.$API.FILE_TYPES_ADMIN, 'post', formData, () => {
        this.fireSuccess('File created successfully!');
        this.showForm = false;
        this.$refs.vtable.init();
        this.form = {
          file_type_id: null,
          title: null,
          file: null,
        };
      });
    },
  },
};
</script>

<style scoped>
.md-card-header .title {
  margin-bottom: 1em;
}
.md-card-header .subtitle li {
  margin: 0;
}
.md-card-header .subtitle li a {
  font-weight: bold;
  color: #9c27b0 !important;
}
</style>
